<template>
    <Head>
        <title>COSTA Cruise's</title>
    </Head>

    <header class="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main class="main reservation">
        <div class="reservation__inner">
            <Notification
                v-if="error.show"
                :text="error.text[LANGUAGE]"
                @closed="closeNotification"
            ></Notification>
            <Reservation-nav v-else :currentLevel="1"></Reservation-nav>
            <div class="reservation__content">
                <div class="reservation__column">
                    <h2
                        class="
                            reservation__heading
                            reservation__heading--hide-small
                        "
                    >
                        {{ $t('LABELS.cabin-and-accomodation-package') }}
                    </h2>
                    <Cabin
                        v-for="cabin in cabins
                            ?.filter((cabin) => cabin.name?.[LANGUAGE])
                            ?.sort((a, b) => b.availability - a.availability)"
                        :key="cabin.code"
                        :code="cabin.code"
                        :images="cabin.images"
                        :name="cabin.name?.[LANGUAGE]"
                        :location="cabin.location?.[LANGUAGE]"
                        :prices="cabin.prices"
                        :adults-num="overview.adultsNum"
                        :children-num="overview.childrenNum"
                        :availability="cabin.availability"
                        @choseCabin="chooseCabin"
                    ></Cabin>
                </div>
                <div class="reservation__column">
                    <h2
                        class="
                            reservation__heading
                            reservation__heading--hide-small
                        "
                    >
                        {{ $t('LABELS.trip-info') }}
                    </h2>
                    <ReservationSide
                        :destination="overview.destinationName[LANGUAGE]"
                        :departurePort="overview.departurePortName[LANGUAGE]"
                        :departureDate="overview.departureDate"
                        :arrivalDate="overview.arrivalDate"
                        :duration="overview.duration"
                        :itinerary="overview.itinerary[LANGUAGE]"
                        :adultsNum="overview.adultsNum"
                        :childrenNum="overview.childrenNum"
                        :crewSummary="false"
                        :cruiserName="workflow.cruise?.ship_name[LANGUAGE]"
                        :msc="msc"
                        @updatedPeople="updatePeople"
                    ></ReservationSide>
                    <h2 class="reservation__heading">
                        {{ $t('LABELS.info-about-cabins') }}
                    </h2>
                    <ReservationDash
                        :wysiwyg="true"
                        v-html="$t('TEXTS.cabin-info-text')"
                    >
                    </ReservationDash>
                    <h2 class="reservation__heading">
                        {{ $t('LABELS.info-about-packages') }}
                    </h2>
                    <ReservationDash :wysiwyg="true" v-html="packagesInfo">
                    </ReservationDash>
                </div>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <Loading
        :is-loading="isLoading"
        :showBar="true"
        :progress="loadingProgress"
        :label="cabinLoadingMessages[loadingMessageIndex]"
    ></Loading>
    <Loading
        :is-loading="!isLoading && saving"
        :label="$t('STATUSES.saving-cabin')"
    ></Loading>
</template>

<script>
import camelCase from 'camelcase';

import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationNav from '../../components/ReservationNav.vue';
import ReservationSide from '../../components/ReservationSide.vue';
import Notification from '../../components/Notification.vue';
import { Api } from '../../modules/Api';
import Loading from '../../components/Loading.vue';
import Cabin from '../../components/Cabin.vue';
import ReservationDash from '../../components/ReservationDash.vue';

import { Head } from '@vueuse/head';

import { LANGUAGE } from '@/env';

export default {
    components: {
        Footer,
        MainNav,
        ReservationNav,
        ReservationSide,
        Notification,
        Loading,
        Cabin,
        ReservationDash,
        Head,
    },
    data() {
        return {
            isLoading: false,
            saving: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            overview: {
                workflowId: '',
                cruiseCode: this.$route.params.cruise_code,
                destinationName: {
                    cs: '',
                    sk: '',
                },
                departurePortName: {
                    cs: '',
                    sk: '',
                },
                departureDate: '2021-08-16',
                arrivalDate: '2021-08-23',
                duration: 0,
                itinerary: {
                    cs: [],
                    sk: [],
                },
                adultsNum: 1,
                childrenNum: 0,
                children1Num: 0,
                children11Num: 0,
                children17Num: 0,
            },
            cabins: [],
            error: {
                show: false,
                text: {
                    cs: [],
                    sk: [],
                },
            },
            workflow: {},
            packagesInfo: '',
            cabinLoadingMessages: [
                this.$t('STATUSES.loading-cabins-1'),
                this.$t('STATUSES.loading-cabins-2'),
                this.$t('STATUSES.loading-cabins-3'),
                this.$t('STATUSES.loading-cabins-4'),
                this.$t('STATUSES.loading-cabins-5'),
                this.$t('STATUSES.loading-cabins-6'),
            ],
            loadingMessageIndex: 0,
            loadingInterval: null,
            loadingProgress: 0,
            msc: false,
        };
    },
    methods: {
        async handleWorkflow() {
            this.overview.workflowId = await this.initWorkflow();
        },
        async initWorkflow() {
            let workflows = localStorage.getItem('workflows')
                ? JSON.parse(localStorage.getItem('workflows'))
                : {};
            if (Object.keys(workflows).length === 0) {
                localStorage.setItem('workflows', '{}');
            }
            const workflowId = await this.api.initWorkflow(
                this.overview.cruiseCode
            );

            workflows = JSON.parse(localStorage.getItem('workflows'));
            workflows[this.overview.cruiseCode] = workflowId;
            localStorage.setItem('workflows', JSON.stringify(workflows));

            return workflowId;
        },
        async loadCabins() {
            // Prepare the age categories object if msc is true
            const ageCategories = this.msc
                ? {
                      children1Num: this.overview.children1Num,
                      children11Num: this.overview.children11Num,
                      children17Num: this.overview.children17Num,
                  }
                : {};

            const { cabins, workflow, cruise } = await this.api.getCabins(
                this.overview.workflowId,
                this.overview.adultsNum,
                this.overview.childrenNum,
                ageCategories // Pass the age categories
            );

            console.log(cabins, workflow, cruise);

            this.msc = cruise.import_source === 'msc';

            if (!cabins || !workflow || !cruise) {
                this.$router.push(
                    `/rezervace/${this.overview.cruiseCode}/nenalezeno/`
                );
            }

            this.cabins = cabins;
            this.workflow = workflow;

            Object.keys(cruise).map((key) => {
                if (key === 'days') {
                    this.overview.duration = cruise[key];
                } else {
                    this.overview[camelCase(key)] = cruise[key];
                }
            });
        },
        async loadPackagesNote() {
            const myCruise = await this.api.getSnippet('mycruise-info');
            const allInclusive = await this.api.getSnippet('myallinc-info');
            const lastMinute = await this.api.getSnippet('mylastminute-info');
            this.packagesInfo =
                myCruise[LANGUAGE] +
                '<br>' +
                allInclusive[LANGUAGE] +
                '<br>' +
                lastMinute[LANGUAGE];
        },
        async updatePeople(updatedData) {
            Object.assign(this.overview, updatedData);
            if (this.msc) {
                this.updateTotalChildrenNum();
            }
            this.startLoading();
            await this.loadCabins();
            this.stopLoading();
        },
        updateTotalChildrenNum() {
            this.overview.childrenNum =
                this.overview.children1Num +
                this.overview.children11Num +
                this.overview.children17Num;
        },
        async chooseCabin(cabin) {
            if (this.overview.adultsNum === 0) {
                this.showMissingCrewError();
                window.scrollTo(0, 0);
                return;
            }

            const choseCabin = this.cabins.find(
                (choseCabin) => choseCabin.code === cabin.code
            );
            const cabinPrice = choseCabin.prices[cabin.package].eur.amount_int;
            this.saving = true;
            await this.api.getOffers(
                this.overview.workflowId,
                cabin.code,
                cabinPrice,
                cabin.package
            );
            this.saving = false;
            this.$router.push({
                path: `/rezervace/${this.overview.cruiseCode}/doprava/`,
            });
        },
        closeNotification() {
            this.error.show = false;
        },
        showMissingCrewError() {
            this.error.show = true;
            this.error.text = {
                cs: [
                    this.$t('ERRORS.unfilled-passengers-count-1'),
                    this.$t('ERRORS.unfilled-passengers-count-2'),
                ],
                sk: [
                    this.$t('ERRORS.unfilled-passengers-count-1'),
                    this.$t('ERRORS.unfilled-passengers-count-2'),
                ],
            };
        },
        handleErrors() {
            const hash = this.$route.hash;
            if (hash.includes('generic-error')) {
                let newHash = hash
                    .replace('&generic-error', '')
                    .replace('generic-error&', '')
                    .replace('generic-error', '');
                if (newHash.length <= 1) {
                    newHash = '';
                }

                this.error.show = true;
                this.error.text = {
                    cs: [
                        this.$t('ERRORS.reservation-error', {
                            contactPhone: '+420 731 562 127',
                            contactEmail: 'plavby@nautyy.cz',
                        }),
                    ],
                    sk: [
                        this.$t('ERRORS.reservation-error', {
                            contactPhone: '+421 910 626 222',
                            contactEmail: 'plavby@nautyy.sk',
                        }),
                    ],
                };

                this.$router.push({ hash: newHash });
            }
        },
        loadCrew() {
            const query = this.$route.query;
            if (query.adultsNum && !isNaN(query.adultsNum)) {
                this.overview.adultsNum = Number(query.adultsNum);
            }
            if (this.msc) {
                // For MSC, load individual age categories
                if (query.children1Num && !isNaN(query.children1Num)) {
                    this.overview.children1Num = Number(query.children1Num);
                }
                if (query.children11Num && !isNaN(query.children11Num)) {
                    this.overview.children11Num = Number(query.children11Num);
                }
                if (query.children17Num && !isNaN(query.children17Num)) {
                    this.overview.children17Num = Number(query.children17Num);
                }
                this.updateTotalChildrenNum();
            } else {
                if (query.childrenNum && !isNaN(query.childrenNum)) {
                    this.overview.childrenNum = Number(query.childrenNum);
                }
            }
        },
        startLoading() {
            this.isLoading = true;
            this.loadingInterval = setInterval(() => {
                if (this.loadingProgress < 80) {
                    this.loadingProgress++;
                    this.loadingMessageIndex = Math.floor(
                        this.loadingProgress / 13.5
                    );
                }
            }, 250);
        },
        stopLoading() {
            clearInterval(this.loadingInterval);
            this.loadingInterval = null;
            this.loadingProgress = 100;

            setTimeout(() => {
                this.isLoading = false;
                this.loadingProgress = 0;
                this.loadingMessageIndex = 0;
            }, 250);
        },
    },
    beforeMount() {
        this.loadCrew();
    },
    async mounted() {
        this.startLoading();
        await this.handleWorkflow();
        await this.loadCabins();
        await this.loadPackagesNote();
        this.stopLoading();

        this.handleErrors();
    },
    computed: {
        hidePrices() {
            return (
                true ||
                this.overview.adultsNum > 2 ||
                this.overview.childrenNum > 0
            );
        },
        priceType() {
            return this.overview.adultsNum === 1
                ? 'price_single'
                : 'price_double';
        },
    },
};
</script>
