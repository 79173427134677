<template>
    <div class="cabin-delegate-group__container">
        <div class="cabin-delegate-group__heading">
            {{ this.price[0].description[LANGUAGE] }}
        </div>
        <div class="cabin-delegate-group__cabins">
            <div
                v-for="(cabin, index) in cabins"
                :key="index"
                class="cabin-delegate-group__cabin"
            >
                <CabinDelegate
                    :init-adults="cabin.numAdults"
                    :init-children="cabin.numChildren"
                    :price="this.price"
                    :max-people="maxPeople"
                    :currency="currency"
                    @update-cabin="updateCabinData(index, $event)"
                ></CabinDelegate>

                <!-- Remove cabin button -->
                <button
                    :class="[
                        'btn',
                        'btn--blue',
                        'btn--standalone-tiny',
                        'btn--block',
                        'btn--full-width',
                    ]"
                    @click="removeCabin(index)"
                >
                    - {{ $t('LABELS.remove-cabin') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import CabinDelegate from '../components/CabinDelegate.vue';

import { LANGUAGE } from '@/env';

export default {
    components: {
        CabinDelegate,
    },
    props: {
        price: {
            type: Array,
            required: true,
        },
        maxPeople: {
            type: Number,
            default: 4,
        },
        currency: {
            type: String,
            required: true,
            default: 'czk',
        },
    },
    data() {
        return {
            // Array to hold the cabins in the group
            cabins: [],
            LANGUAGE: LANGUAGE,
        };
    },
    methods: {
        addCabin(adults = 0, children = 0) {
            // Add a new cabin to the group
            this.cabins.push({
                numAdults: adults,
                numChildren: children,
                totalPrice: 0,
                fare: '',
                uuid: '',
            });
        },
        removeCabin(index) {
            // Remove the cabin at the specified index
            this.cabins.splice(index, 1);
            this.emitGroupUpdate();
        },

        updateCabinData(index, cabinData) {
            this.cabins[index] = cabinData;
            this.emitGroupUpdate();
        },
        emitGroupUpdate() {
            this.$emit('update-group', {
                code: this.price[0].code,
                cabins: this.cabins,
                uuids: this.price.map((price) => price.id),
            });
        },
    },
};
</script>
