<template>
    <div class="loading" :class="{ 'loading--show': isLoading }">
        <svg
            v-if="TYPE === 'yacht'"
            :class="`loading__icon loading__icon--${type}`"
        >
            <use :href="loadingIconUrl"></use>
        </svg>
        <img
            v-else
            :class="`loading__icon loading__icon--${type}`"
            src="/assets/imgs/icons/loading-icon.svg"
        />
        <div class="loading__bar" v-if="showBar">
            <div
                :class="`loading__progress loading__progress--${type}`"
                :style="{ width: `${progress}%` }"
            ></div>
        </div>
        <p class="loading__label">{{ label }}...</p>
    </div>
</template>
<script>
import { TYPE } from '@/env';

export default {
    props: ['isLoading', 'label', 'showBar', 'progress'],
    data() {
        return {
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser',
            startTime: null,
        };
    },
    computed: {
        loadingIconUrl() {
            if (TYPE === 'yachtas') {
                return '/assets/imgs/icons/sprite.svg#yacht';
            } else if (TYPE === 'cruises') {
                return '/assets/imgs/icons/loading-icon.svg';
            }

            return '';
        },
    },
    watch: {
        isLoading: {
            immediate: true,
            handler(newIsLoading, oldIsLoading) {
                if (newIsLoading) {
                    this.startTime = performance.now();
                } else if (oldIsLoading && this.startTime) {
                    const endTime = performance.now();
                    const duration = endTime - this.startTime;
                    console.log(
                        `Loading took ${duration.toFixed(2)} milliseconds`
                    );
                    this.startTime = null;
                }
            },
        },
    },
};
</script>
